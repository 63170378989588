@mixin AvatarBorder($color) {
    border: 2px solid $color !important;
}

@mixin Avatar($size, $fontSize) {
    display: inline-block;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: none !important;

    &.avatar-border {
        border: 2px solid #bebebe !important;
    }

    .avatar-img {
        border-radius: 4rem;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .avatar-text {
        font-weight: 700;
        color: white;
        font-size: $fontSize;
    }
}

.avatar {
    @include Avatar(4rem, 1.5rem);
}

.avatar-sm {
    @include Avatar(2rem, .75rem);
}

.avatar-lg {
    @include Avatar(8rem, 4rem);
}

.avatar-xl {
    @include Avatar(10rem, 6rem);
}

.avatar-auth {
    @include Avatar(16rem, 10rem);
    @media screen and (max-width: 576px) {
        @include Avatar(8rem, 4rem);
    }
}